import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

const messages = {
    'en': {
        languageselect:"English",

        navbarAllStore:"Stores",
        navbarMap:"Map",
        navbarUsers:"Users",
        navbarAdmins:"Admins",
        navbarTypes:"Store Types",
        navbarLogout:"Logout",
        navbarBack:"Back",


        loginTitle:"Sign in as an Admin",
        loginEmail:"Email",
        loginPassword:"Password",
        loginPasswordEdit:"Edit Password (Optional)",
        loginBtn:"Login",
        //errors
        loginEmailError:"Enter Your Correct Email",
        loginUnauthorized:"The Account is not Authorized to Enter This Website",
        loginWrongEmail:"Please write your email correctly",
        loginUnAuth:"Wrong Password Or Email",

        mainLocationAlert:"Allow Location access to proceed",
        mainUserName:"Sales Representer Name",
        mainIsUserDeleted:"Is Account Deleted?",
        mainAdminName:"Admin Name",

        mainStoreType:"Store Type",
        mainStoreTypeAr:"Store Type in Arabic",
        mainStoreTypeEn:"Store Type in English",
        mainStoreOwnerName:"Store Owner Name",
        mainStoreName:"Store Name",
        mainPhoneNumber:"Phone Number",
        mainPhoneNumber2:"Second Phone Number (Optional)",
        mainCity:"City",
        mainAllCity:"All Cities",
        mainNone:"Empty",
        mainTableEmpty: "No Data",
        mainDeleted: "Deleted",
        mainEmptyFields: "Search Fields Are Empty",

        mainStatus:"Store Status",
        mainDescriptin:"Description",
        mainDate:"Schedule a date",
        mainAccNum:"Account Number",
        mainRejectionReason:"Reason of Rejection",
        mainIsBankUser:"Is a bank user?",

        mainPhoto:"Store Image",
        imageName:"Click Here",
        mainBtn:"Submit",
        updateBtn:"Update",
        okBtn:"Ok",

        //errors
        mainStoreTypeError:"Enter Store Type",
        mainStoreTypeAdd:"Enter Store Type",

        mainStoreOwnerNameError:"Enter Store Owner Name",
        mainStoreNameError:"Enter Store Name",
        mainPhoneNumberError:"Enter Phone Number",
        mainPhoneNumber2Error:"Second Phone Number (Optional)",
        mainCityError:"Select City",
        mainStatusError:"Select Store Status",
        mainDescriptinError:"Enter Description",
        mainPhotoError:"Store Image",
        alertLocationِError:"An Error Occourd While Tyring to Get Your Location, Please Try Again.",
        alertEnterAllFields:"Enter All Fields",
        alertSeccesful:"Successfully Added",
        alertUpdate:"Updated",
        alertAdded:"Added",

        alertLocationِBrowserError:"Your Broswer Doesn't support location services",
        alertFileType:"Invalid File Type",
        alertPhone:"Invalid Phone Number",
        alertEmailUser:"Email is already registered",
        alertEmailOrNameUser:"Email or Name is already registered",
        alertError:"Error Occurred",
        alertdelete_sure:"Are You Sure?",
        alertdelete:"Deleted",
        alertNoChange:"Nothing Changed",
        UserName:"User Name",

        UserEmail:"Email",
        AdminName:"Admin Name",

        createdat:"Created At",
        coordinates:"Coordinates",
        actions:"Actions",
        editBtn:"Edit",
        addBtn:"Add",
        cancelBtn:"Cancel",
        continueBtn:"Continue"

    },
    'ar': {
        languageselect:"العربية",

        navbarAllStore:"المتاجر",
        navbarMap:"الخارطة",
        navbarUsers:"المندوبين",
        navbarAdmins:"المسؤولين",
        navbarTypes:"انواع المتجر",

        navbarLogout:"تسجيل الخروج",
        navbarBack:"الرجوع",

        loginTitle:"تسجيل دخول كمسؤول",
        loginEmail:"البريد الالكتروني ",
        loginPassword:"الرمز السري",
        loginPasswordEdit:"تعديل الرمز السري (اختياري)",
        loginBtn:"دخول",
        //errors
        loginEmailError:"يرجى كتابة البريد الالكتروني بشكل صحيح",
        loginUnauthorized:"الحساب غير مصرح بالدخول",
        loginWrongEmail:"يرجى كتابة البريد الاللكتروني بشكل صحيح",
        loginUnAuth:"الرمز السري او البريد الالكتروني خاطئ",


        mainLocationAlert:"اقبل الوصول الى الموقع لاكمال عملية التسجيل",
        mainUserName:"اسم المندوب",
        mainIsUserDeleted:"هل الحساب محذوف ؟",
        mainAdminName:"اسم المسؤول",

        mainStoreType:"نوع المتجر",
        mainStoreTypeAr:"نوع المتجر في العربية",
        mainStoreTypeEn:"نوع المتجر في الانكليزية",

        mainStoreTypeAdd:"اكتب نوع المتجر",
        mainStoreOwnerName:"اسم صاحب المتجر",
        mainStoreName:"اسم المتجر",
        mainPhoneNumber:"رقم الهاتف",
        mainPhoneNumber2:"رقم الهاتف الثاني (اختياري)",
        mainCity:"المحافظة",
        mainAllCity:"جميع المحافظات",
        mainNone:"لا يوجد",
        mainTableEmpty: "لا توجد بيانات",
        mainDeleted: "تم الحذف",

        mainEmptyFields: "حقول البحث فارغة",

        mainStatus:"حالة المتجر",
        mainDescriptin:"المتطلبات",
        mainDate:"تحديد موعد",
        mainAccNum:"رقم الحساب البنكي",
        mainRejectionReason:"سبب الرفض",
        mainIsBankUser:"يمتلك حساب في البنك؟",

        mainPhoto:"صورة المتجر",
        imageName:"اضغط هنا",

        mainBtn:"ارسال",
        updateBtn:"تحديث",
        okBtn:"تم",

        //errors
        mainStoreTypeError:"يرجى اختيار نوع المتجر",
        mainStoreOwnerNameError:"يرجى ادخال اسم صاحب المتجر",
        mainStoreNameError:"يرجى ادخال اسم المتجر",
        mainPhoneNumberError:"يرجى ادخال رقم الهاتف الخاص بصاحب المتجر",
        mainCityError:"يرجى اختيار المحافظة",
        mainStatusError:"يرجى اختيار حالة المتجر",
        mainDescriptinError:"يرجى كتابه المتطلبات",
        mainPhotoError:"يرجى اختيار صورة للمتجر",
        alertLocationِError:"حصل خطأ في الحصول على موقع المتجر يرجى المحاولة من جديد",
        alertEnterAllFields:"يرجى ادخال جميع الحقول",
        alertSeccesful:"تم الاضافة بنجاح",
        alertUpdate:"تم التحديث",
        alertAdded:"تم الاضافة",
        alertLocationِBrowserError:"المتصفح لا يدعم خدمة المواقع",
        alertFileType:"صيغة الملف غير مدعومة",
        alertPhone:"رقم الهاتف غير صحيح",
        alertEmailUser:"البريد الالكتروني مستخدم",
        alertEmailOrNameUser:"البريد الالكتروني او الاسم مستخدم",

        alertError:"حصل خطأ في عملية التسجيل",
        alertdelete_sure:"هل انت واثق من عملية الحذف؟",
        alertdelete:"تم الحذف",
        alertNoChange:"لم يتغير شيء",

        UserName:"اسم المندوب",
        AdminName:"اسم المسؤول",
        UserEmail:"البريد الالكتروني",
        createdat:"تاريخ الاضافة",
        coordinates:"الاحداثيات",
        actions:"الاجرائات",
        editBtn:"تعديل",
        addBtn:"اضافة",
        cancelBtn:"الغاء",
        continueBtn:"متابعة"
    }
};
export default  new VueI18n({
    locale: 'ar',
    messages, 
});